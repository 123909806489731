// MailTo.jsx

/*
  PWA mailto solution:
  https://stackoverflow.com/questions/55610036/is-there-a-way-to-use-the-mailto-or-message-scheme-in-a-pwa-in-ios-12-2
*/

'use strict';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class MailTo extends PureComponent {
  handleClick = e => {
    const {
      subject,
      isIntercomFallback,
      isUserInfoRequired,
      hasIntercomUserHash,
      hasClientId,
      submitEmailToSupport,
      sendCurrentRemoteConfig,
    } = this.props;
    const isIntercomAlive = window?.Intercom?.booted;
    try {
      if (isIntercomFallback && isIntercomAlive) {
        if (isUserInfoRequired) {
          if (!hasIntercomUserHash)
            throw new Error('Intercom User Hash is Required.');
          if (!hasClientId) throw new Error('Intercom Client Id is Required.');
        }
      } else {
        throw new Error('Intercom is shutdown');
      }
    } catch (err) {
      e.preventDefault();
      submitEmailToSupport({ subject });
      sendCurrentRemoteConfig();
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return (
      <StyledMailTo {...rest} onClick={this.handleClick}>
        {children}
      </StyledMailTo>
    );
  }
}

MailTo.propTypes = {
  children: PropTypes.node,
  subject: PropTypes.string,
  isIntercomFallback: PropTypes.bool,
  isUserInfoRequired: PropTypes.bool,
  hasIntercomUserHash: PropTypes.bool,
  hasClientId: PropTypes.bool,
  submitEmailToSupport: PropTypes.func,
  sendCurrentRemoteConfig: PropTypes.func,
};

MailTo.defaultProps = {
  subject: '',
  isIntercomFallback: false,
  isUserInfoRequired: false,
  hasClientId: false,
  submitEmailToSupport: () => null,
  sendCurrentRemoteConfig: () => null,
};

const StyledMailTo = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  color: inherit;
  padding: 0;
  margin: 0;
  background-color: transparent;
`;

export default MailTo;
